.pagination {
    display: flex;
    padding-left: 0;
    border-radius: 4px;

    ul {
        padding-left: 15px;
        padding-right: 15px;
    }

    li {
        list-style-type: none;

    }

    &__link{
        position: relative;
        float: left;
        padding: 6px 12px;
        margin-left: -1px;
        line-height: 1.42857143;
        text-decoration: none;    
        border: 1px solid #ddd;
    }
    &__active  { 
        z-index: 3;
        color: #fff;
        cursor: default;
        background-color: #337ab7;
        border-color: #337ab7;
    }
}


