.repository {
    box-sizing: border-box;
    min-height: 90px;
    margin: 0 0 20px;
    padding: 20px 120px 20px 20px;
    border-radius: 7px;
    background: #fff;
    box-shadow: 0 3px 11px rgba(125, 128, 147, 0.23);
  
    &__link{
      font-weight: bold;
      color: #000;
    }
}
  