@font-face {
  font-family: "Roboto";
  src: url("fonts/Roboto-Regular.ttf") format("truetype");
}

body {
  background: #fffcfc;
  font: 18px/1 "Roboto", sans-serif;
}

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__subject {
    box-sizing: border-box;
    display: block;
    margin: 7px 0 15px 0;
    padding: 5px 5px 5px 20px;
    width: 400px;
    height: 50px;
    border: 1px solid rgba(125, 128, 147, 0.23);
    border-radius: 2px;
    box-shadow: 0 3px 11px rgba(125, 128, 147, 0.23);
    font-size: 15px;
    color: rgb(125, 128, 147);
  }
}
